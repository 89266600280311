<template>
<div>
  <div class="position-relative">
    <div class="shapes">
      <img class="shape-1" src="./../assets/svg/abstract_45.svg"/>
    </div>
    <h5 class="font-weight-normal my-5">Co środę o 21:00 ks. Maciej Gierula na <a href="https://www.facebook.com/FundacjaProSpe/" class="source-facebook font-weight-bold">Facebook’owym profilu Fundacji Pro Spe</a> odpowiada na zadane przez Was pytania.</h5>
    <div class="mt-3">
      <h5>Dołącz do nas i zadaj pytanie już dziś!</h5>
      <Sources/>
    </div>
  </div>

</div>
</template>

<script>
import Sources from "@/components/Sources";

export default {
  name: "JoinUs",
  components: {Sources}
}
</script>

<style scoped>

</style>
