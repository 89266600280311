<template>
<div>
  <h5 class="font-weight-bold mb-5">Zobacz najnowsze odcinki Zapytaj Księdza</h5>
  <div class="row mb-3">
    <div class="col-sm-4">
      <div class="video-wrapper">
        <div class="video m-1">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/izGvT8lDn58" title="Zapytaj księdza 2x03" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="video-wrapper">
        <div class="video m-1">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/gJvBkXSy8rk" title="Zapytaj księdza 2x02" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="video-wrapper">
        <div class="video m-1">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/uUlZ-hO2d9Q" title="Zapytaj księdza 2x01" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "LatestVideos"
}
</script>

<style scoped>

</style>
