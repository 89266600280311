<template>
  <b-navbar toggleable="lg" sticky="true" class="bg-white">
    <b-navbar-brand href="/" class="logo"><span class="font-weight-bold">#</span><span class="text-main">zapytaj</span>księdza</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="/#about" class="">O fundacji</b-nav-item>
<!--        <b-nav-item href="Videos">Poprzednie Q&A</b-nav-item>-->
          <b-nav-item href="/questions">Lista pytań</b-nav-item>
        <b-nav-item href="/articles">Video Blog</b-nav-item>
        <b-nav-item href="https://prospe.org/">Wesprzyj fundację</b-nav-item>
        <b-nav-item href="/#contact">Kontakt</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Header",
  components: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/styles";

  .navbar-light {
    .navbar-brand {
      transition: 0.3s;
      &:hover, &.active {
        transform: scale(1.05);
      }
    }
    .navbar-nav .nav-link {
      color: $dark;
      letter-spacing: 0.2px;
      font-weight: 500;
      transition: all 0.3s;
      &:hover, &.active {
        color: $main-color;
      }
    }
  }
</style>
