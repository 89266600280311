<template>
  <footer class="page-footer bg-dark text-white text-center" id="contact">
    <div class="row">
      <div class="col-sm-4">
        <img width="100" src="../assets/images/logo-prospe-white.png"/>
        <div class="mt-3">
          <Sources/>
        </div>
      </div>
      <div class="col-sm-4 mt-4 mt-sm-0">
        <p>Fundacja Pro Spe</p>
        <p>ul. S. Jabłońskiego 7/7</p>
        <p>35-068 Rzeszów</p>
        <p class="mt-2">Biuro:</p>
        <p>ul. Śreniawitów 8/2</p>
        <p>35-032 Rzeszów</p>
        <p>tel. 797 55 55 01</p>
      </div>
      <div class="col-sm-4 mt-4 mt-sm-0">
        <p>info@prospe.org</p>
        <p>www.prospe.org</p>
        <p class="mt-4">KRS 0000607263</p>
        <p>Regon 363961250</p>
      </div>
    </div>
  </footer>
</template>

<script>
import Sources from "@/components/Sources";

export default {
  name: "Footer",
  components: {Sources}
}
</script>

<style lang="scss" scoped>
  .page-footer {
    padding: 80px 30px 50px 30px;
    margin-top: 60px;
    border-top-left-radius: 50% 60px;
    border-top-right-radius: 50% 60px;
    p {
      margin: 0;
    }
  }
</style>
