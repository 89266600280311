





























import {Component, Vue} from "vue-property-decorator";
import Settings from "@/packages/settings/Settings";

@Component
export default class LatestQuestions extends Vue {
  public episode = {};
  public settings: Settings;
  public actualPage = 1;

  constructor() {
    super();
    this.settings = new Settings();

  }

  public loadData(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.axios.get(this.settings.settings.host + '/api/episodes',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc',
        limit: 1,
        status: 1
      }
    }).then(response => {
      if (response.data['hydra:member'][0] !== undefined) {
        that.episode = response.data['hydra:member'][0];
      }
    });
  }
  getVideoCodeFromLink(link: string): string {
    return link
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('&ab_channel=FundacjaProSpe', '')
  }

  created(): void {
    this.loadData();
    console.log('latest episode')
  }
}
