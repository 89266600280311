<template>
  <div class="main-screen py-5 px-sm-5" id="home">
    <div class="container">
      <h1 class="page-title logo mt-3">
        <span class="font-weight-bold">#</span>
        <span class="text-light-main">zapytaj</span>
        <span>księdza</span>
      </h1>
      <div class="px-sm-5">
        <h5 class="my-5">
          Nurtują Cię pytania dotyczące wiary, moralności, teologii? A może zwykła codzienność sprawia, że chcesz rozwiać swoje wątpliwości  i zadać pytanie?
        </h5>
        <h5 class="mb-5">Odpowiedzi udziela ks. Maciej Gierula, założyciel <a href="https://prospe.org/" class="link-white font-weight-bold">Fundacji Pro Spe</a>, niosącej pomoc przede wszystkim do dzieci, osób starszych i niepełnosprawnych w Gruzji.</h5>
        <h5 class="">
          Zobacz sesje Q&A - dotychczas powstało 50 odcinków! Nowe odpowiedzi pojawiają się w każdą środę o 21:00
        </h5>
      </div>
      <div class="my-5">
        <b-button class="btn-brown btn-zoom btn-lg m-3" href="questions">Zobacz zadane już pytania</b-button>
        <AskQuestion/>
      </div>
    </div>
  </div>
</template>

<script>
import AskQuestion from "@/components/AskQuestion";

export default {
  name: "Main",
  components: {AskQuestion}
}
</script>

<style lang="scss" scoped>

.main-screen {
  background-color: #ffffff;
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23ffffff'/%3E%3Cstop offset='1' stop-color='%23b7d4f7'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='24' height='24' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='12' cy='12' r='12'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
  /* background by SVGBackgrounds.com */
  background-image: url('../assets/images/main-bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  margin-bottom: 100px;
  z-index: 0;
  border-bottom-left-radius: 50% 60px;
  border-bottom-right-radius: 50% 60px;
  position: relative;
  color: white;
  .page-title {
    font-size: 56px;
    @media (max-width: 767px) {
      font-size: 36px;
    }
  }
  h5 {
    font-weight: 400;
  }
}

</style>
