export class Auth {
    public static setToken(token: string): void {
        localStorage.setItem('token', token);
        localStorage.setItem('expiry', (Math.round(Date.now() / 1000) + 3600 * 24).toString());
    }

    public static getToken(): string | null {
        const token = localStorage.getItem('token');
        const expiry = localStorage.getItem('expiry');
        const actualTime = Math.floor(Date.now() / 1000);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (actualTime > expiry) {
            localStorage.removeItem('token');
            localStorage.removeItem('expir');
            return null;
        }
        if (!token) {
            return null;
        }

        return token;
    }

    public static destroyToken(): void {
        localStorage.removeItem('token');
    }
    
    public static isAuthenticated(): boolean {
        return !!this.getToken();
    }
}