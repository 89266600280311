import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import {Auth} from "@/packages/auth/Auth";
import Pagination from 'vue-pagination-2';

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Notifications)
Vue.component('pagination', Pagination);

router.beforeEach(
    (to, from, next) => {
      if (to.matched.some(record => record.meta.forVisitors)) {
        if (Auth.isAuthenticated()) {
          // next({
          //   path: '/home'
          // })
            next();
        } else next()
      } else next()

      if (to.matched.some(record => record.meta.forAuth)) {
        if (!Auth.isAuthenticated()) {
          next({
            path: '/login'
          })
        } else next()
      }
    }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
