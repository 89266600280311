<template>
  <div>
    <a class="source-icon source-facebook" href="https://www.facebook.com/FundacjaProSpe/" target="_blank"><b-icon icon="facebook"></b-icon></a>
    <a class="source-icon source-youtube" href="https://www.youtube.com/channel/UC2bVUk8hwansNwvhmDk1UhQ" target="_blank"><b-icon icon="youtube"></b-icon></a>
    <a class="source-icon source-instagram" href="https://www.instagram.com/fundacjaprospe/" target="_blank"><b-icon icon="instagram"></b-icon></a>
  </div>
</template>

<script>
export default {
  name: "Sources"
}
</script>

<style lang="scss" scoped>

.source-facebook {
  color: #4267B2;
}
.source-youtube {
  color: #FF0000;
}
.source-instagram {
  color: #C13584;
}

.source-icon {
  width: 40px;
  height: 40px;
  font-size: 24px;
  padding: 2px;
  display: inline-block;
  margin: 5px;
  border-radius: 50%;
  background-color: white;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
    svg {
      color: inherit;
    }
  }
}

</style>
