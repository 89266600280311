<template>
<div class="position-relative">
<!--  <div class="shapes">-->
<!--    <img class="shape-1" src="./../assets/svg/abstract_30.svg"/>-->
<!--    <img class="shape-4" src="./../assets/svg/abstract_16.svg"/>-->
<!--  </div>-->
  <h5 class="font-weight-bold">O fundacji</h5>
  <div class="my-4">
    <img src="../assets/images/logo-prospe-black.png" width="150"/>
  </div>
  <div class="mt-4">
      <p>Pro Spe czyli „ku nadziei”</p>
      <p>
        Fundacja Pro Spe powstała 15.03.2016 r.
        Posiadamy status organizacji pożytku publicznego.
        Naszą misją jest wspieranie dzieł misjonarzy w Gruzji służąc dzieciom, ubogim, chorym i niepełnosprawnym.
      </p>
  </div>
  <div class="mt-5">
    <h6 class="font-weight-bold mb-0">Wsparcie działań fundacji:</h6>
    <iframe width="100%" height="380" style="border: 0" src="https://payment.prospe.atpsolutions.pl/payment/form/c11473cf-46f0-46b4-82f7-8203cadab9e6"></iframe>
  </div>

  <div class="mt">
    <h6 class="font-weight-bold mb-3">15 marca 2021 roku minęło 5 lat istnienia Fundacji. Przez ten czas:</h6>
    <div class="col-sm-10 offset-sm-1 text-left">
      <ul class="pl-3">
        <li>Sfinansowaliśmy 9 kolonii uśmiechu dla ponad 250 dzieci</li>
        <li>Dzieci z Domku Babci i przedszkola w Khizabavrze odbyły ponad 4056 godzin nauki</li>
        <li>️Zapewniliśmy 109 240 posiłków dla 125 dzieci w Domku Babci i przedszkoli w Khizabavrze oraz Tbilisi</li>
        <li>️7980 godzin rehabilitacji dla prawie 1000 dzieci w ośrodku Ojców Kamilianów</li>
        <li>️84 000 przejechanych kilometrów, podczas dowozu dzieci niepełnosprawnych na rehabilitację</li>
        <li>Wysłaliśmy 5 Tirów pełnych sprzętu rehabilitacyjnego do Gruzji i 2 na Ukrainę</li>
        <li>Wydaliśmy ponad 14 040 posiłków ubogim w Abchazji</li>
        <li>Zapewniliśmy pomoc uchodźcom wojennym w Armenii w postaci posiłków, materaców i leków</li>
        <li>Wysłaliśmy 135 różnej wielkości paczek z opatrunkami, gazikami, plastrami czy wodą utlenioną do Republiki Środkowej Afryki</li>
      </ul>
    </div>
    <p class="mt-4">Wspieramy również ciepłym posiłkiem bezdomnych w Tbilisi, oraz lekami i pomocą hospicjum dla chorych. W ciągu 5 lat działalności podarowaliśmy również strzykawki dla chorych dzieci, środki czystości dla Polaków na Litwie. W czasie Pandemii pomogliśmy Polonii w Gruzji a także wysłaliśmy wielu wolontariuszy, którzy pomagali misjonarzom.</p>
  </div>
  <div class="mt-5">
    <b-button variant="danger" href="https://prospe.org/">Dowiedz się więcej o fundacji Pro Spe</b-button>
  </div>

</div>

</template>

<script>
export default {
  name: "AboutProSpe"
}
</script>

<style scoped>

</style>
