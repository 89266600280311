






































import {Component, Vue} from "vue-property-decorator";
import Settings from "@/packages/settings/Settings";

@Component
export default class AskQuestion extends Vue {
  public email: string;
  public question: string;
  public settings: Settings;

  public constructor() {
    super();
    this.email = '';
    this.question = '';
    this.settings = new Settings();
  }

  public onSubmit(): void {
    console.log(this.email, this.question);
    if (this.email.length === 0 || this.question.length === 0) {
      this.$notify({
        group: 'notify',
        type: 'warn',
        text: 'Uzupełnij formularz poprawnie'
      });

    } else {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that: AskQuestion = this;
      this.axios.post(
          this.settings.settings.host + '/api/questions',
          {
            "email": that.email,
            "question": that.question
          }
      ).then(function(data) {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytania zostały wysłane'
        });
      }, function (error) {
        that.$notify({
          group: 'notify',
          type: 'error',
          text: 'Błąd podczas wysyłania pytań. Spróbuj jeszcze raz'
        });
      })
    }

  }
}
