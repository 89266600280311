<template>
 <div>
   <Main/>
   <section>
     <Biogram/>
   </section>
   <section class="bg-lightblue">
     <div class="container p-5">
       <LatestQuestions/>
     </div>
   </section>
   <section class="">
     <div class="container p-5">
       <LatestVideos/>
     </div>
   </section>
   <section class="border-light-top">
     <div class="container py-5">
       <div class="text-center">
         <h5 class="font-weight-bold mb-5">Baza pytań Q&A - dotychczas powstało już 50 odcinków! </h5>
         <p class="mb-5">Zobacz jakie pytania zostały zadane w poprzednich odcinkach. Zachęcamy do obejrzenia naszych filmów na YouTube. Możesz również wyszukać interesujące Cię pytanie lub zadać nowe.</p>
         <b-button class="btn-blue" href="questions">Zobacz pytania z poprzednich odcinków Q&A</b-button>
       </div>
     </div>
   </section>
<!--   <section>-->
<!--     <div class="container px-5 pt-5 mt-5" id="ProSpe">-->
<!--       <h5 class="font-weight-bold">Wsparcie fundacji Pro Spe</h5>-->
<!--       <iframe src="https://payment.prospe.atpsolutions.ovh/" title="Payment" width="100%" height="500" frameBorder="0"></iframe>-->
<!--     </div>-->
<!--   </section>-->
   <section class="bg-lightblue" id="about">
     <div class="container py-5 px-3 px-sm-5">
       <AboutProSpe/>
     </div>
   </section>
   <section>
     <div class="container py-5 px-3 px-sm-5">
      <JoinUs/>
     </div>
   </section>
 </div>
</template>

<script>
// @ is an alias to /src
// import AskQuestion from "@/components/AskQuestion";
import Biogram from "@/components/Biogram"
import LatestQuestions from "@/components/LatestQuestions";
import JoinUs from "@/components/JoinUs"
import Main from "@/components/Main"
import AboutProSpe from "@/components/AboutProSpe";
import LatestVideos from "@/components/LatestVideos";

export default {
  name: 'Home',
  components: {
    LatestVideos,
    AboutProSpe,
    Biogram,
    LatestQuestions,
    JoinUs,
    Main
  }
}
</script>
