<template>
  <div id="app">
    <notifications group="notify" />
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import "assets/styles/styles";

#app {
  //font-family: Raleway, Helvetica, Arial, sans-serif;
  //font-family: Lato, Helvetica, Arial, sans-serif;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $dark;
}

.modal-dialog {
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Header}
}
</script>
