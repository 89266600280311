<template>
  <div class="position-relative">
    <div class="shapes">
      <img class="shape-2" src="./../assets/svg/abstract_18.svg"/>
      <img class="shape-3" src="./../assets/svg/abstract_14.svg"/>
    </div>
    <div class="container">
      <div class="m-5">
        <div class="row">
          <div class="col-sm-4">
            <b-img width="220" src="./../assets/images/ks-maciej-gierula.jpg" rounded="circle" alt="Circle image"></b-img>
          </div>
          <div class="col-sm-8 mt-3 mt-sm-0">
            <h4 class="font-weight-bold">Na pytania odpowiada ks. Maciej Gierula</h4>
            <p class="mt-4">
              Wielki miłośnik podróży. Fundator i pomysłodawca <a href="https://prospe.org/" class="link-main">Fundacji Pro Spe</a>, której misją jest wspieranie dzieł misjonarzy w Gruzji poprzez służbę dzieciom, ubogim, chorym i niepełnosprawnym.
              Obecnie pracuje dla Administracji Apostolskiej dla Katolików na Kaukazie. Razem z wolontariuszami i pracownikami fundacji szukają sposobów na pomoc ludziom w różnych częściach świata.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "biogram"
}
</script>

<style scoped>

</style>
