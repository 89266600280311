import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/question/add',
    name: 'QuestionAdd',
    component: () => import('../views/Question/QuestionAdd.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/articles',
    name: 'ArticlesShortList',
    component: () => import('../views/Article/ArticleListShort.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/articles/:id',
    name: 'ArticleView',
    component: () => import('../views/Article/ArticleView.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      forVisitors: true
    }
  },
  {
    path: '/panel',
    name: 'Panel',
    component: () => import('../views/Panel.vue'),
    meta: {
      forAuth: true
    },
    children: [
      {
        path: 'question/list',
        name: 'QuestionList',
        component: () => import('../views/Question/QuestionList.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'question/:id',
        name: 'QuestionDetail',
        component: () => import('../views/Question/QuestionDetail.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'episode/list',
        name: 'EpisodeList',
        component: () => import('../views/Episode/EpisodeList.vue'),
        meta: {
          forAuth: true
        }
      },

      {
        path: 'episode/add',
        name: 'EpisodeAdd',
        component: () => import('../views/Episode/EpisodeForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'episode/:id',
        name: 'EpisodeDetail',
        component: () => import('../views/Episode/EpisodeDetail.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'episode/:id/edit',
        name: 'EpisodeEdit',
        component: () => import('../views/Episode/EpisodeForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'episode/:episodeId/question/:questionId',
        name: 'EpisodeQuestionEdit',
        component: () => import('../views/Episode/EpisodeQuestionForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'episode/:episodeId/question',
        name: 'EpisodeQuestionAdd',
        component: () => import('../views/Episode/EpisodeQuestionForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'goal/list',
        name: 'GoalList',
        component: () => import('../views/Goal/GoalList.vue'),
        meta: {
          forAuth: true
        }
      },

      {
        path: 'goal/add',
        name: 'GoalAdd',
        component: () => import('../views/Goal/GoalForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'goal/:id',
        name: 'GoalDetail',
        component: () => import('../views/Goal/GoalDetail.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'goal/:id/edit',
        name: 'GoalEdit',
        component: () => import('../views/Goal/GoalForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'article/list',
        name: 'ArticleList',
        component: () => import('../views/Article/ArticleList.vue'),
        meta: {
          forAuth: true
        }
      },

      {
        path: 'article/add',
        name: 'ArticleAdd',
        component: () => import('../views/Article/ArticleForm.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'article/:id',
        name: 'ArticleDetail',
        component: () => import('../views/Article/ArticleDetail.vue'),
        meta: {
          forAuth: true
        }
      },
      {
        path: 'article/:id/edit',
        name: 'ArticleEdit',
        component: () => import('../views/Article/ArticleForm.vue'),
        meta: {
          forAuth: true
        }
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
